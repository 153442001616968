import list from "../../../styles/list.module.css";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
  Grid,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState, useEffect, useRef } from "react";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import img1 from "../../../assets/images/science_3_color.png";
import img2 from "../../../assets/images/science_4_color.png";
import img3 from "../../../assets/images/science_5_color.png";
import img4 from "../../../assets/images/science_6_color.png";
import img5 from "../../../assets/images/science_7_color.png";

import img1_b from "../../../assets/images/science_3_color_back.png";
import img2_b from "../../../assets/images/science_4_color_back.png";
import img3_b from "../../../assets/images/science_5_color_back.png";
import img4_b from "../../../assets/images/science_6_color_back.png";
import img5_b from "../../../assets/images/science_7_color_back.png";

import img6 from "../../../assets/images/science_8_color.png";
import img6_b from "../../../assets/images/science_8_color_back.png";
import img7 from "../../../assets/images/science_7.png";
import img7_b from "../../../assets/images/science_7_back.png";
import img8 from "../../../assets/images/school_1.png";
import img8_b from "../../../assets/images/school_1_back.png";
import { CommonAxios } from "../../CommonAxios";

import Slider from "react-slick";
const ListContent = ({ select, rightColor }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const [listData, setListData] = useState([]);
  const [listData1, setListData1] = useState([]);
  const [listData2, setListData2] = useState([]);
  useEffect(() => {
    let sendData = {
      class_project_no: select,
      class_category_no: rightColor,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_class",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setListData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, [rightColor]);

  useEffect(() => {
    if (listData) {
      let oneArr = [];
      let twoArr = [];

      let a = "t";
      for (let i = 0; i < listData.length; i++) {
        if (a === "t") {
          if (i !== 0) {
            if (i % 3 === 0) {
              if (i % 2 !== 0) {
                twoArr.push(listData[i]);
                a = "f";
              }
            } else {
              oneArr.push(listData[i]);
            }
          } else {
            oneArr.push(listData[i]);
          }
        } else {
          if (i !== 0) {
            if (i % 3 === 0) {
              if (i % 2 === 0) {
                oneArr.push(listData[i]);
                a = "t";
              }
            } else {
              twoArr.push(listData[i]);
            }
          } else {
            twoArr.push(listData[i]);
          }
        }
      }

      let oneA = [];
      let twoA = [];
      let threeA = [];
      let ab = "t";
      for (let i = 0; i < listData.length; i++) {
        if (i !== 0) {
          if (ab === "t") {
            if (i % 2 === 0) {
              twoA.push(listData[i]);
              ab = "f";
            } else {
              oneA.push(listData[i]);
            }
          } else if (ab === "f") {
            if (i % 2 === 0) {
              threeA.push(listData[i]);
              ab = "c";
            } else {
              twoA.push(listData[i]);
            }
          } else {
            if (i % 2 === 0) {
              oneA.push(listData[i]);
              ab = "t";
            } else {
              threeA.push(listData[i]);
            }
          }
        } else {
          oneA.push(listData[i]);
        }
      }
      console.log([...oneA, ...twoA, ...threeA]);
      setListData1([...oneArr, ...twoArr]);
      setListData2([...oneA, ...twoA, ...threeA]);
    }
  }, [listData]);
  const data = [
    {
      img: img1,
      link: "https://ddot.kr/kiz/202203/202203.html",
      type: 1,
      back: img1_b,
    },
    {
      img: img2,
      link: "https://ddot.kr/kiz/202204/202204.html",
      type: 1,
      back: img2_b,
    },
    {
      img: img3,
      link: "https://ddot.kr/kiz/202205/202205.html",
      type: 1,
      back: img3_b,
    },
    {
      img: img4,
      link: "https://ddot.kr/kiz/202206/202206.html",
      type: 1,
      back: img4_b,
    },
    {
      img: img5,
      link: "https://ddot.kr/kiz/202207/202207.html",
      type: 1,
      back: img5_b,
    },
    {
      img: img6,
      link: "https://ddot.kr/kiz/202208/202208.html",
      type: 1,
      back: img6_b,
    },

    {
      img: img7,
      link: "https://ddot.kr/202109/202109.html",
      type: 2,
      back: img7_b,
    },
  ];
  const data1 = [
    {
      img: img8,
      link: "https://ddot.kr/2-1/2-1.html",
      type: 2,
      back: img8_b,
    },
  ];

  const settings = {
    dots: true,

    className: "center",
    centerMode: false,
    infinite: false,
    // centerPadding: "120px",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settings1 = {
    dots: true,
    className: "center",
    centerMode: false,
    infinite: false,
    // centerPadding: "120px",
    slidesToShow: 1,
    speed: 500,
    rows: 3,
    slidesPerRow: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const ListItem = ({ data }) => {
    return (
      <>
        <div className={list.list_item_img}>
          <a href={data.class_link} className={list.list_item_link}>
            <img
              src={`/class/${data.no}/${data.class_img}`}
              className={list.list_item_image}
            />
            <img
              src={`/class/${data.no}/${data.class_img_back}`}
              className={list.list_item_back}
            />
          </a>
        </div>
      </>
    );
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ButtonNext1 onClick={onClick}>
        {" "}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {" "}
          <BiSolidRightArrow />
        </div>
      </ButtonNext1>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ButtonPrev1
        onClick={onClick}

        // style={{ width: "100px", height: "100px", background: "blue" }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <BiSolidLeftArrow />
        </div>
      </ButtonPrev1>
    );
  }

  return (
    <>
      <div className={list.list_inner}>
        <div className={list.list_content_box}>
          <div className={list.list_content}>
            <div className="common_list_swiper">
              <Slider
                {...settings}
                style={{ height: "100%" }}
                className="mySwiper_pc"
              >
                {listData.length > 0 &&
                  listData.map((data, index) => {
                    return (
                      <div>
                        <ListItem data={data} />
                      </div>
                    );
                  })}
              </Slider>

              <Slider
                {...settings1}
                style={{ height: "100%" }}
                className="mySwiper_m"
              >
                {listData.length > 0 &&
                  listData.map((data, index) => {
                    return (
                      <div>
                        <ListItem data={data} />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
const ButtonPrev1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 100px;
  color: #2d2288;
  top: 50%;
  left: -100px;
  margin-top: -50px;
  font-size: 40px;
  @media screen and (max-width: 1600px) {
    left: -70px;
  }
  @media screen and (max-width: 1040px) {
    left: -60px;
    font-size: 30px;
  }
  @media screen and (max-width: 760px) {
    left: -50px;
    font-size: 26px;
  }
`;
const ButtonNext1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 100px;
  color: #2d2288;
  top: 50%;
  right: -100px;
  margin-top: -50px;
  font-size: 40px;
  @media screen and (max-width: 1600px) {
    right: -70px;
  }
  @media screen and (max-width: 1040px) {
    right: -60px;
    font-size: 30px;
  }
  @media screen and (max-width: 760px) {
    right: -50px;
    font-size: 26px;
  }
`;

export default ListContent;
