import LessonDivTableList from "./LessonDivTableList";

const QnaDivDatableList = ({ datas }) => {
  return (
    <>
      {datas &&
        datas.map((data) => <LessonDivTableList key={data.no} data={data} />)}
      {/* <Paging count={notices.length} /> */}
    </>
  );
};

export default QnaDivDatableList;
