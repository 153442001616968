import { createAction, handleActions } from "redux-actions";
import * as authAPI from "../lib/api/invest";
import createRequestThunk from "../lib/api/createRequestThunk";

//회원 가입
const SELECT_INVEST = "invest/SELECT_INVEST";
const SELECT_INVEST_SUCCESS = "invest/SELECT_INVEST_SUCCESS";

const RESECT = "invest/RESECT";

export const investReset = createAction(RESECT);

export const selectInvest = createRequestThunk(
  SELECT_INVEST,
  authAPI.selectInvest
);

//투자하러가기 연동
const GET_INVEST_ACMOUNT = "invest/GET_INVEST_ACMOUNT";

export const getAmount = createAction(GET_INVEST_ACMOUNT);

const initialState = {
  invest: null,
  amount: null,
};

const invest = handleActions(
  {
    [SELECT_INVEST_SUCCESS]: (state, action) => ({
      ...state,
      invest: action.payload.messageinfo.message,
    }),
    [GET_INVEST_ACMOUNT]: (state, action) => ({
      ...state,
      amount: action.payload,
    }),

    [RESECT]: (state) => ({
      ...state,
      invest: null,
      amount: null,
    }),
  },
  initialState
);

export default invest;
