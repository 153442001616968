import { useEffect, useRef, useState, useCallback } from "react";
import notice from "../../styles/notice.module.css";
import { FiSearch } from "react-icons/fi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import CustomCateTable from "./detail/CustomCateTable";
import { CommonAxios } from "../CommonAxios";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import NoticeViewDetail from "./detail/NoticeViewDetail";
import { useNavigate } from "react-router-dom";

const NoticeView = () => {
  const [filterView, setFilterview] = useState(false);
  const navigate = useNavigate();
  const TableColumn = [
    {
      Header: "분류",
      accessor: "notice_category",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div className={notice.notice_category_div}>
              {data.value === 1 ? "공지" : "이벤트"}
            </div>
          </>
        );
      },
    },
    {
      Header: "제목",
      accessor: "notice_title",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div>{data.value}</div>
          </>
        );
      },
    },
    {
      Header: "작성일",
      accessor: "notice_date",
      Cell: ({ cell: { value } }) => <Date value={value} />,
    },
  ];
  const { id } = useParams();
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD">{value}</Moment>;
    } else {
      return <>기간 미지정</>;
    }
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_notice_view",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  const click_list = () => {
    setFilterview(!filterView);
  };

  const dropRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        filterView &&
        dropRef.current &&
        !dropRef.current.contains(e.target)
      ) {
        setFilterview(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [filterView]);

  const [searchFilter, setSerarchFilter] = useState("전체");
  const [searchValue, setSearchValue] = useState("");
  const searchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const enterkey = (e) => {
    if (e.key === "Enter") {
      btn_search(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const btn_search = () => {
    let sendData = {
      search: searchValue,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_notice",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
        }
      }
    );
  };

  return (
    <>
      <div id="wrapper">
        <div id="container">
          <div className={notice.notice}>
            <div className={notice.wrap}>
              {" "}
              <div className={notice.notice_box}>
                <div className={notice.notice_top}>
                  <div>공지사항</div>
                </div>
                <div className={notice.notice_content_box}>
                  <div className={notice.notice_table_wrap}>
                    {arrayList.length > 0 && (
                      <>
                        {" "}
                        <div className={notice.notice_table_view}>
                          {arrayList.length > 0 && (
                            <NoticeViewDetail data={arrayList[0]} />
                          )}
                        </div>{" "}
                        <div className={notice.notice_re}>
                          <button onClick={() => navigate(-1)}>이전으로</button>
                        </div>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeView;
