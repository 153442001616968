import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { CommonAxios } from "../components/CommonAxios";
import NoticeView from "../components/notice/NoticeView";

const NoticeViewPage = () => {
  return (
    <>
      <NoticeView />
    </>
  );
};

export default NoticeViewPage;
