import my from "../../styles/my.module.css";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
  Grid,
} from "swiper/modules";
import img1 from "../../assets/images/sub_text01.png";
import img2 from "../../assets/images/sub_text02.png";
import img3 from "../../assets/images/sub_text03.png";
import img4 from "../../assets/images/sub_text04.png";
import img5 from "../../assets/images/sub_text05.png";
import img6 from "../../assets/images/sub_text06.png";
import { FaPlusCircle, FaGraduationCap } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState, useEffect, useRef } from "react";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const MyClass = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigation = useNavigate();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const [day, setDay] = useState("");
  const [mon, setMon] = useState("");
  const [date, setDate] = useState("");
  useEffect(() => {
    const now = new Date();
    const dayNum = now.getDay();
    const month = now.getMonth() + 1;
    const dateNum = now.getDate();

    setDay(dayNum);
    setMon(month > 9 ? month : "0" + month);
    setDate(dateNum > 9 ? dateNum : "0" + dateNum);
  }, []);

  useEffect(() => {
    if (user) {
    } else {
      navigation("/");
    }
  }, []);
  const pagination = {
    clickable: true,
    // renderBullet: function (index, className) {
    //   return (
    //     '<span style={opacity:"0"} class="' +
    //     className +
    //     '">' +
    //     (index + 1) +
    //     "</span>"
    //   );
    // },
  };

  return (
    <>
      <div id="wrapper">
        <div id="container">
          <div className={my.my}>
            {" "}
            <div className={my.rowgroup}>
              <div className={`${my.my_class_flex} ${my.wrap}`}>
                <div className={`${my.temp_calendar} `}>
                  <div className={my.calendar_top}>
                    <div className={my.calendar_top_inner}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="56.799"
                        height="50"
                        viewBox="0 0 56.799 50"
                      >
                        <g
                          id="그룹_34"
                          data-name="그룹 34"
                          transform="translate(-116.46 -190)"
                        >
                          <rect
                            id="사각형_13"
                            data-name="사각형 13"
                            width="54.945"
                            height="45.329"
                            rx="2.835"
                            transform="translate(117.388 193.743)"
                            fill="#fff"
                          />
                          <path
                            id="패스_55"
                            data-name="패스 55"
                            d="M170.58,240H119.14a2.683,2.683,0,0,1-2.68-2.68V195.5a2.683,2.683,0,0,1,2.68-2.68h51.44a2.683,2.683,0,0,1,2.679,2.68V237.32A2.683,2.683,0,0,1,170.58,240Zm-51.44-45.33a.826.826,0,0,0-.825.826V237.32a.826.826,0,0,0,.825.826h51.44a.826.826,0,0,0,.825-.826V195.5a.826.826,0,0,0-.825-.826Z"
                            fill="#40b3e6"
                          />
                          <circle
                            id="타원_6"
                            data-name="타원 6"
                            cx="3.228"
                            cy="3.228"
                            r="3.228"
                            transform="translate(124.05 196.559)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_14"
                            data-name="사각형 14"
                            width="3.353"
                            height="8.654"
                            rx="0.838"
                            transform="translate(125.601 190.618)"
                            fill="#231815"
                          />
                          <path
                            id="패스_56"
                            data-name="패스 56"
                            d="M127.358,199.89H127.2a2.216,2.216,0,0,1-2.214-2.214v-5.461A2.217,2.217,0,0,1,127.2,190h.161a2.217,2.217,0,0,1,2.214,2.215v5.461A2.216,2.216,0,0,1,127.358,199.89Zm-.161-8.654a.98.98,0,0,0-.978.979v5.461a.979.979,0,0,0,.978.978h.161a.979.979,0,0,0,.978-.978v-5.461a.98.98,0,0,0-.978-.979Z"
                            fill="#231815"
                          />
                          <circle
                            id="타원_7"
                            data-name="타원 7"
                            cx="3.228"
                            cy="3.228"
                            r="3.228"
                            transform="translate(141.632 196.559)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_15"
                            data-name="사각형 15"
                            width="3.353"
                            height="8.654"
                            rx="0.838"
                            transform="translate(143.183 190.618)"
                            fill="#231815"
                          />
                          <path
                            id="패스_57"
                            data-name="패스 57"
                            d="M144.94,199.89h-.161a2.216,2.216,0,0,1-2.214-2.214v-5.461A2.217,2.217,0,0,1,144.779,190h.161a2.218,2.218,0,0,1,2.215,2.215v5.461A2.217,2.217,0,0,1,144.94,199.89Zm-.161-8.654a.98.98,0,0,0-.978.979v5.461a.979.979,0,0,0,.978.978h.161a.979.979,0,0,0,.978-.978v-5.461a.979.979,0,0,0-.978-.979Z"
                            fill="#231815"
                          />
                          <circle
                            id="타원_8"
                            data-name="타원 8"
                            cx="3.228"
                            cy="3.228"
                            r="3.228"
                            transform="translate(159.214 196.559)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_16"
                            data-name="사각형 16"
                            width="3.353"
                            height="8.654"
                            rx="0.838"
                            transform="translate(160.765 190.618)"
                            fill="#231815"
                          />
                          <path
                            id="패스_58"
                            data-name="패스 58"
                            d="M162.523,199.89h-.161a2.217,2.217,0,0,1-2.215-2.214v-5.461A2.218,2.218,0,0,1,162.362,190h.161a2.218,2.218,0,0,1,2.214,2.215v5.461A2.217,2.217,0,0,1,162.523,199.89Zm-.161-8.654a.979.979,0,0,0-.978.979v5.461a.979.979,0,0,0,.978.978h.161a.979.979,0,0,0,.978-.978v-5.461a.98.98,0,0,0-.978-.979Z"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_17"
                            data-name="사각형 17"
                            width="41.62"
                            height="26.579"
                            transform="translate(124.05 206.484)"
                            fill="#40b3e6"
                          />
                          <rect
                            id="사각형_18"
                            data-name="사각형 18"
                            width="8.417"
                            height="9.581"
                            transform="translate(132.631 213.901)"
                            fill="#fff100"
                          />
                          <rect
                            id="사각형_19"
                            data-name="사각형 19"
                            width="41.62"
                            height="1.236"
                            transform="translate(124.05 213.283)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_20"
                            data-name="사각형 20"
                            width="41.62"
                            height="1.236"
                            transform="translate(124.05 222.864)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_21"
                            data-name="사각형 21"
                            width="1.236"
                            height="26.579"
                            transform="translate(132.013 206.484)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_22"
                            data-name="사각형 22"
                            width="1.236"
                            height="26.579"
                            transform="translate(140.43 206.484)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_23"
                            data-name="사각형 23"
                            width="1.236"
                            height="26.579"
                            transform="translate(148.847 206.484)"
                            fill="#231815"
                          />
                          <rect
                            id="사각형_24"
                            data-name="사각형 24"
                            width="1.236"
                            height="26.579"
                            transform="translate(157.264 206.484)"
                            fill="#231815"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className={my.top_box}>
                    <div className={my.month_box}>
                      <div className={`${my.cont_btn} ${my.prev}`}>
                        <button className={my.prev1}>
                          <TiArrowSortedUp />{" "}
                        </button>
                      </div>
                      <ul className={`${my.month_list}`}>
                        <li
                          className={
                            day === 1
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em>월</em>
                            </div>
                          </a>
                          <a href="#n" className={my.month_anchor}>
                            <em>월</em>
                          </a>
                        </li>
                        <li
                          className={
                            day === 2
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          <a href="#n" className={my.month_anchor}>
                            <em>화</em>
                          </a>
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em>화</em>
                            </div>
                          </a>
                        </li>
                        <li
                          className={
                            day === 3
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          {" "}
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em>수</em>
                            </div>
                          </a>
                          <a href="#n" className={my.month_anchor}>
                            <em>수</em>
                          </a>
                        </li>
                        <li
                          className={
                            day === 4
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          {" "}
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em>목</em>
                            </div>
                          </a>
                          <a href="#n" className={my.month_anchor}>
                            <em>목</em>
                          </a>
                        </li>
                        <li
                          className={
                            day === 5
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          {" "}
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em>금</em>
                            </div>
                          </a>
                          <a href="#n" className={my.month_anchor}>
                            <em>금</em>
                          </a>
                        </li>
                        <li
                          className={
                            day === 6
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          {" "}
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em>토</em>
                            </div>
                          </a>
                          <a href="#n" className={my.month_anchor}>
                            <em>토</em>
                          </a>
                        </li>
                        <li
                          className={
                            day === 0
                              ? `${my.month_item} ${my.active}`
                              : `${my.month_item}`
                          }
                        >
                          {" "}
                          <a href="#n" className={my.month_anchor_active}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="123.558"
                              height="112.953"
                              viewBox="0 0 123.558 112.953"
                            >
                              <g
                                id="그룹_29"
                                data-name="그룹 29"
                                transform="translate(-83.221 -438.008)"
                              >
                                <path
                                  id="패스_54"
                                  data-name="패스 54"
                                  d="M205.279,494.784a21.76,21.76,0,0,0-12.8-19.83,21.764,21.764,0,0,0-28.692-24.338,21.76,21.76,0,0,0-37.889-.107,21.75,21.75,0,0,0-28.377,24.445,21.761,21.761,0,0,0-.1,39.617,21.99,21.99,0,0,0-.245,3.267A21.762,21.762,0,0,0,125.9,538.459a21.76,21.76,0,0,0,37.889-.106,21.769,21.769,0,0,0,29.034-20.515,21.992,21.992,0,0,0-.245-3.267A21.762,21.762,0,0,0,205.279,494.784Z"
                                  fill="#fff"
                                  stroke="#dcdddd"
                                  stroke-miterlimit="10"
                                  stroke-width="3"
                                />
                              </g>
                            </svg>{" "}
                            <div>
                              <span>{`${mon}.${date}`}</span>
                              <em></em>
                            </div>
                          </a>
                          <a href="#n" className={my.month_anchor}>
                            <em>일</em>
                          </a>
                        </li>
                      </ul>
                      <div className={`${my.cont_btn} ${my.next}`}>
                        <button className={my.next1}>
                          {" "}
                          <TiArrowSortedDown />{" "}
                        </button>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className={my.class_group_wrap}>
                  <div className={my.class_group}>
                    <div className={my.class_top}>
                      {" "}
                      <div className={my.class_top_box}>
                        <div className={my.class_top_left}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="57.475"
                            height="34.574"
                            viewBox="0 0 57.475 34.574"
                          >
                            <g
                              id="그룹_397"
                              data-name="그룹 397"
                              transform="translate(-635.564 -197.713)"
                            >
                              <path
                                id="패스_431"
                                data-name="패스 431"
                                d="M647.462,214.268v15.024l.846.394c.228.106,5.694,2.6,15.4,2.6a66.68,66.68,0,0,0,17.642-2.517l1.075-.3v-16.4l-19.747,7.207Z"
                                fill="#fff"
                              />
                              <path
                                id="패스_432"
                                data-name="패스 432"
                                d="M687.182,219.5a1.121,1.121,0,0,0-.361-.823v-9.363l6.218-2.357-28.92-9.245-28.555,9.629,27.09,10.705,21.258-7.759-17.19-4.989,1.608-.659,17.027,4.749v9.229a1.119,1.119,0,0,0,.1,1.839,5.567,5.567,0,0,0-1.145,4.184h3.55a5.99,5.99,0,0,0-1.089-4.28A1.123,1.123,0,0,0,687.182,219.5Z"
                                fill="#fff"
                              />
                            </g>
                          </svg>

                          <span>키잼학습</span>
                        </div>
                        <div className={my.class_top_right}>
                          총 3강의 학습계획이 있습니다.
                        </div>
                      </div>
                    </div>
                    <div className={`${my.calendar_list}`}>
                      <Swiper
                        className="myClass_Swiper"
                        pagination={pagination}
                        slidesPerView={1}
                        ref={setSwiper}
                        {...parmss}
                        style={{ position: "relative" }}
                        modules={[Navigation, Pagination]}
                        breakpoints={{
                          // 640: {
                          //   slidesPerView: 2,
                          // },
                          800: {
                            slidesPerView: 2,
                          },
                          1150: {
                            slidesPerView: 3,
                          },
                        }}
                      >
                        {" "}
                        <SwiperSlide>
                          <div className={my.calendar_item}>
                            <a
                              href="#n"
                              className={`${my.calendar_anchor} ${my.n1}`}
                            >
                              <div className={my.calendar_inner_back_one}></div>
                              <div className={my.calendar_inner_back_two}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="420.645"
                                  height="533.35"
                                  viewBox="0 0 420.645 533.35"
                                >
                                  <defs>
                                    <clipPath id="clip-학습_배경_1">
                                      <rect width="420.645" height="533.35" />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="학습_배경_1"
                                    clip-path="url(#clip-학습_배경_1)"
                                  >
                                    <g
                                      id="그룹_685"
                                      data-name="그룹 685"
                                      transform="translate(-2056.062 -2218.907)"
                                    >
                                      <path
                                        id="빼기_1"
                                        data-name="빼기 1"
                                        d="M390.645,533.351H30a29.975,29.975,0,0,1-30-30V30A29.974,29.974,0,0,1,30,0H328.218a30,30,0,0,1,21.214,8.787l62.426,62.427a30,30,0,0,1,8.787,21.213V503.351a29.975,29.975,0,0,1-30,30ZM30,13.5A16.518,16.518,0,0,0,13.5,30V503.35A16.518,16.518,0,0,0,30,519.85H390.645a16.518,16.518,0,0,0,16.5-16.5V92.426a16.389,16.389,0,0,0-4.833-11.668L339.886,18.332A16.392,16.392,0,0,0,328.218,13.5ZM390.645,516.85H30a13.515,13.515,0,0,1-13.5-13.5V30A13.515,13.515,0,0,1,30,16.5H328.218a13.412,13.412,0,0,1,9.546,3.954L400.191,82.88a13.412,13.412,0,0,1,3.954,9.546V503.35A13.515,13.515,0,0,1,390.645,516.85Z"
                                        transform="translate(2056.062 2218.907)"
                                        fill="#40b3e6"
                                        stroke="rgba(0,0,0,0)"
                                        stroke-miterlimit="10"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={my.calendar_inner}>
                                <p className={my.c_num}>1교시</p>
                                <p className={my.c_title}>키잼과학</p>
                                <p className={my.c_text}>
                                  <em className={my.em_yellow}>2023 특호</em>
                                  1차시 내 공을 잡아줘
                                  <em className={my.em_yellow}>
                                    메이커과학, 중력
                                  </em>
                                  <span className={my.page}>교재 P. 1-6</span>
                                </p>
                                <p className={`${my.edu_status} ${my.n2}`}>
                                  <span>학습중</span>
                                </p>
                              </div>
                            </a>
                          </div>{" "}
                        </SwiperSlide>{" "}
                        <SwiperSlide>
                          <div className={my.calendar_item}>
                            <a
                              href="#n"
                              className={`${my.calendar_anchor} ${my.n2}`}
                            >
                              {" "}
                              <div className={my.calendar_inner_back_one}></div>
                              <div className={my.calendar_inner_back_two}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="420.645"
                                  height="533.35"
                                  viewBox="0 0 420.645 533.35"
                                >
                                  <defs>
                                    <clipPath id="clip-학습_배경_1">
                                      <rect width="420.645" height="533.35" />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="학습_배경_1"
                                    clip-path="url(#clip-학습_배경_1)"
                                  >
                                    <g
                                      id="그룹_685"
                                      data-name="그룹 685"
                                      transform="translate(-2056.062 -2218.907)"
                                    >
                                      <path
                                        id="빼기_1"
                                        data-name="빼기 1"
                                        d="M390.645,533.351H30a29.975,29.975,0,0,1-30-30V30A29.974,29.974,0,0,1,30,0H328.218a30,30,0,0,1,21.214,8.787l62.426,62.427a30,30,0,0,1,8.787,21.213V503.351a29.975,29.975,0,0,1-30,30ZM30,13.5A16.518,16.518,0,0,0,13.5,30V503.35A16.518,16.518,0,0,0,30,519.85H390.645a16.518,16.518,0,0,0,16.5-16.5V92.426a16.389,16.389,0,0,0-4.833-11.668L339.886,18.332A16.392,16.392,0,0,0,328.218,13.5ZM390.645,516.85H30a13.515,13.515,0,0,1-13.5-13.5V30A13.515,13.515,0,0,1,30,16.5H328.218a13.412,13.412,0,0,1,9.546,3.954L400.191,82.88a13.412,13.412,0,0,1,3.954,9.546V503.35A13.515,13.515,0,0,1,390.645,516.85Z"
                                        transform="translate(2056.062 2218.907)"
                                        fill="#abcf4b"
                                        stroke="rgba(0,0,0,0)"
                                        stroke-miterlimit="10"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={my.calendar_inner}>
                                <p className={my.c_num}>2교시</p>
                                <p className={my.c_title}>돌봄늘봄 학교생활</p>
                                <p className={my.c_text}>
                                  <em className={my.em_yellow}>
                                    2023 1호 학교와 나
                                  </em>
                                  2차시 학교 꾸미기
                                  <em className={my.em_yellow}>
                                    꾸미기, 퍼즐, 색칠공부
                                  </em>
                                  <span className={my.page}>교재 P. 1-11</span>
                                </p>
                                <p className={`${my.edu_status} ${my.n1}`}>
                                  <span>학습 시작</span>
                                </p>
                              </div>
                            </a>
                          </div>{" "}
                        </SwiperSlide>{" "}
                        <SwiperSlide>
                          <div className={my.calendar_item}>
                            <a
                              href="#n"
                              className={`${my.calendar_anchor} ${my.n3}`}
                            >
                              {" "}
                              <div className={my.calendar_inner_back_one}></div>
                              <div className={my.calendar_inner_back_two}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="420.645"
                                  height="533.35"
                                  viewBox="0 0 420.645 533.35"
                                >
                                  <defs>
                                    <clipPath id="clip-학습_배경_1">
                                      <rect width="420.645" height="533.35" />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="학습_배경_1"
                                    clip-path="url(#clip-학습_배경_1)"
                                  >
                                    <g
                                      id="그룹_685"
                                      data-name="그룹 685"
                                      transform="translate(-2056.062 -2218.907)"
                                    >
                                      <path
                                        id="빼기_1"
                                        data-name="빼기 1"
                                        d="M390.645,533.351H30a29.975,29.975,0,0,1-30-30V30A29.974,29.974,0,0,1,30,0H328.218a30,30,0,0,1,21.214,8.787l62.426,62.427a30,30,0,0,1,8.787,21.213V503.351a29.975,29.975,0,0,1-30,30ZM30,13.5A16.518,16.518,0,0,0,13.5,30V503.35A16.518,16.518,0,0,0,30,519.85H390.645a16.518,16.518,0,0,0,16.5-16.5V92.426a16.389,16.389,0,0,0-4.833-11.668L339.886,18.332A16.392,16.392,0,0,0,328.218,13.5ZM390.645,516.85H30a13.515,13.515,0,0,1-13.5-13.5V30A13.515,13.515,0,0,1,30,16.5H328.218a13.412,13.412,0,0,1,9.546,3.954L400.191,82.88a13.412,13.412,0,0,1,3.954,9.546V503.35A13.515,13.515,0,0,1,390.645,516.85Z"
                                        transform="translate(2056.062 2218.907)"
                                        fill="#f5a220"
                                        stroke="rgba(0,0,0,0)"
                                        stroke-miterlimit="10"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={my.calendar_inner}>
                                <p className={my.c_num}>3교시</p>
                                <p className={my.c_title}>플레이 우드메이커</p>
                                <p className={my.c_text}>
                                  <em className={my.em_yellow}>저학년 3호</em>
                                  1차시 TV 무드등
                                  <span className={my.page}>교재 P. 1-5</span>
                                </p>
                                <p className={`${my.edu_status} ${my.n1}`}>
                                  <span>학습 시작</span>
                                </p>
                              </div>
                            </a>
                          </div>{" "}
                        </SwiperSlide>{" "}
                        <SwiperSlide>
                          <div className={my.calendar_item}>
                            <a
                              href="#n"
                              className={`${my.calendar_anchor} ${my.n2}`}
                            >
                              {" "}
                              <div className={my.calendar_inner_back_one}></div>
                              <div className={my.calendar_inner_back_two}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="420.645"
                                  height="533.35"
                                  viewBox="0 0 420.645 533.35"
                                >
                                  <defs>
                                    <clipPath id="clip-학습_배경_1">
                                      <rect width="420.645" height="533.35" />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="학습_배경_1"
                                    clip-path="url(#clip-학습_배경_1)"
                                  >
                                    <g
                                      id="그룹_685"
                                      data-name="그룹 685"
                                      transform="translate(-2056.062 -2218.907)"
                                    >
                                      <path
                                        id="빼기_1"
                                        data-name="빼기 1"
                                        d="M390.645,533.351H30a29.975,29.975,0,0,1-30-30V30A29.974,29.974,0,0,1,30,0H328.218a30,30,0,0,1,21.214,8.787l62.426,62.427a30,30,0,0,1,8.787,21.213V503.351a29.975,29.975,0,0,1-30,30ZM30,13.5A16.518,16.518,0,0,0,13.5,30V503.35A16.518,16.518,0,0,0,30,519.85H390.645a16.518,16.518,0,0,0,16.5-16.5V92.426a16.389,16.389,0,0,0-4.833-11.668L339.886,18.332A16.392,16.392,0,0,0,328.218,13.5ZM390.645,516.85H30a13.515,13.515,0,0,1-13.5-13.5V30A13.515,13.515,0,0,1,30,16.5H328.218a13.412,13.412,0,0,1,9.546,3.954L400.191,82.88a13.412,13.412,0,0,1,3.954,9.546V503.35A13.515,13.515,0,0,1,390.645,516.85Z"
                                        transform="translate(2056.062 2218.907)"
                                        fill="#ABCF4A"
                                        stroke="rgba(0,0,0,0)"
                                        stroke-miterlimit="10"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={my.calendar_inner}>
                                <p className={my.c_num}>2교시</p>
                                <p className={my.c_title}>돌봄늘봄 학교생활</p>
                                <p className={my.c_text}>
                                  <em className={my.em_yellow}>
                                    2023 1호 학교와 나
                                  </em>
                                  2차시 학교 꾸미기
                                  <em className={my.em_yellow}>
                                    꾸미기, 퍼즐, 색칠공부
                                  </em>
                                  <span className={my.page}>교재 P. 1-11</span>
                                </p>
                                <p className={`${my.edu_status} ${my.n1}`}>
                                  <span>학습 시작</span>
                                </p>
                              </div>
                            </a>
                          </div>{" "}
                        </SwiperSlide>{" "}
                        <SwiperSlide>
                          <div className={my.calendar_item}>
                            <a
                              href="#n"
                              className={`${my.calendar_anchor} ${my.n1}`}
                            >
                              {" "}
                              <div className={my.calendar_inner_back_one}></div>
                              <div className={my.calendar_inner_back_two}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="420.645"
                                  height="533.35"
                                  viewBox="0 0 420.645 533.35"
                                >
                                  <defs>
                                    <clipPath id="clip-학습_배경_1">
                                      <rect width="420.645" height="533.35" />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="학습_배경_1"
                                    clip-path="url(#clip-학습_배경_1)"
                                  >
                                    <g
                                      id="그룹_685"
                                      data-name="그룹 685"
                                      transform="translate(-2056.062 -2218.907)"
                                    >
                                      <path
                                        id="빼기_1"
                                        data-name="빼기 1"
                                        d="M390.645,533.351H30a29.975,29.975,0,0,1-30-30V30A29.974,29.974,0,0,1,30,0H328.218a30,30,0,0,1,21.214,8.787l62.426,62.427a30,30,0,0,1,8.787,21.213V503.351a29.975,29.975,0,0,1-30,30ZM30,13.5A16.518,16.518,0,0,0,13.5,30V503.35A16.518,16.518,0,0,0,30,519.85H390.645a16.518,16.518,0,0,0,16.5-16.5V92.426a16.389,16.389,0,0,0-4.833-11.668L339.886,18.332A16.392,16.392,0,0,0,328.218,13.5ZM390.645,516.85H30a13.515,13.515,0,0,1-13.5-13.5V30A13.515,13.515,0,0,1,30,16.5H328.218a13.412,13.412,0,0,1,9.546,3.954L400.191,82.88a13.412,13.412,0,0,1,3.954,9.546V503.35A13.515,13.515,0,0,1,390.645,516.85Z"
                                        transform="translate(2056.062 2218.907)"
                                        fill="#40b3e6"
                                        stroke="rgba(0,0,0,0)"
                                        stroke-miterlimit="10"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={my.calendar_inner}>
                                <p className={my.c_num}>1교시</p>
                                <p className={my.c_title}>키잼과학</p>
                                <p className={my.c_text}>
                                  <em className={my.em_yellow}>2023 특호</em>
                                  1차시 내 공을 잡아줘
                                  <em className={my.em_yellow}>
                                    메이커과학, 중력
                                  </em>
                                  <span className={my.page}>교재 P. 1-6</span>
                                </p>
                                <p className={`${my.edu_status} ${my.n2}`}>
                                  <span>학습중</span>
                                </p>
                              </div>
                            </a>
                          </div>{" "}
                        </SwiperSlide>{" "}
                        <SwiperSlide>
                          <div className={my.calendar_item}>
                            <a
                              href="#n"
                              className={`${my.calendar_anchor} ${my.n3}`}
                            >
                              {" "}
                              <div className={my.calendar_inner_back_one}></div>
                              <div className={my.calendar_inner_back_two}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="420.645"
                                  height="533.35"
                                  viewBox="0 0 420.645 533.35"
                                >
                                  <defs>
                                    <clipPath id="clip-학습_배경_1">
                                      <rect width="420.645" height="533.35" />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="학습_배경_1"
                                    clip-path="url(#clip-학습_배경_1)"
                                  >
                                    <g
                                      id="그룹_685"
                                      data-name="그룹 685"
                                      transform="translate(-2056.062 -2218.907)"
                                    >
                                      <path
                                        id="빼기_1"
                                        data-name="빼기 1"
                                        d="M390.645,533.351H30a29.975,29.975,0,0,1-30-30V30A29.974,29.974,0,0,1,30,0H328.218a30,30,0,0,1,21.214,8.787l62.426,62.427a30,30,0,0,1,8.787,21.213V503.351a29.975,29.975,0,0,1-30,30ZM30,13.5A16.518,16.518,0,0,0,13.5,30V503.35A16.518,16.518,0,0,0,30,519.85H390.645a16.518,16.518,0,0,0,16.5-16.5V92.426a16.389,16.389,0,0,0-4.833-11.668L339.886,18.332A16.392,16.392,0,0,0,328.218,13.5ZM390.645,516.85H30a13.515,13.515,0,0,1-13.5-13.5V30A13.515,13.515,0,0,1,30,16.5H328.218a13.412,13.412,0,0,1,9.546,3.954L400.191,82.88a13.412,13.412,0,0,1,3.954,9.546V503.35A13.515,13.515,0,0,1,390.645,516.85Z"
                                        transform="translate(2056.062 2218.907)"
                                        fill="#f5a220"
                                        stroke="rgba(0,0,0,0)"
                                        stroke-miterlimit="10"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={my.calendar_inner}>
                                <p className={my.c_num}>3교시</p>
                                <p className={my.c_title}>플레이 우드메이커</p>
                                <p className={my.c_text}>
                                  <em className={my.em_yellow}>저학년 3호</em>
                                  1차시 TV 무드등
                                  <span className={my.page}>교재 P. 1-5</span>
                                </p>
                                <p className={`${my.edu_status} ${my.n1}`}>
                                  <span>학습 시작</span>
                                </p>
                              </div>
                            </a>
                          </div>{" "}
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                  <div className={my.class_group_bottom}>
                    <ul>
                      <li>
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="109.644"
                            height="100"
                            viewBox="0 0 109.644 100"
                          >
                            <path
                              id="패스_1038"
                              data-name="패스 1038"
                              d="M681.32,2105.771a19.79,19.79,0,0,0-11.641-18.035,19.795,19.795,0,0,0-26.094-22.135,19.791,19.791,0,0,0-34.459-.1,19.781,19.781,0,0,0-25.809,22.232,19.792,19.792,0,0,0-.088,36.031,19.795,19.795,0,0,0,25.9,21.726,19.79,19.79,0,0,0,34.459-.1,19.8,19.8,0,0,0,26.4-18.658,20.061,20.061,0,0,0-.222-2.971A19.791,19.791,0,0,0,681.32,2105.771Z"
                              transform="translate(-571.676 -2055.499)"
                              fill="#fff23f"
                            />
                          </svg>
                          <div>
                            <img src={img1} />
                          </div>
                        </a>
                      </li>{" "}
                      <li>
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="109.644"
                            height="100"
                            viewBox="0 0 109.644 100"
                          >
                            <path
                              id="패스_1038"
                              data-name="패스 1038"
                              d="M681.32,2105.771a19.79,19.79,0,0,0-11.641-18.035,19.795,19.795,0,0,0-26.094-22.135,19.791,19.791,0,0,0-34.459-.1,19.781,19.781,0,0,0-25.809,22.232,19.792,19.792,0,0,0-.088,36.031,19.795,19.795,0,0,0,25.9,21.726,19.79,19.79,0,0,0,34.459-.1,19.8,19.8,0,0,0,26.4-18.658,20.061,20.061,0,0,0-.222-2.971A19.791,19.791,0,0,0,681.32,2105.771Z"
                              transform="translate(-571.676 -2055.499)"
                              fill="#40B3E6"
                            />
                          </svg>{" "}
                          <div>
                            <img src={img2} />
                          </div>
                        </a>
                      </li>{" "}
                      <li>
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="109.644"
                            height="100"
                            viewBox="0 0 109.644 100"
                          >
                            <path
                              id="패스_1038"
                              data-name="패스 1038"
                              d="M681.32,2105.771a19.79,19.79,0,0,0-11.641-18.035,19.795,19.795,0,0,0-26.094-22.135,19.791,19.791,0,0,0-34.459-.1,19.781,19.781,0,0,0-25.809,22.232,19.792,19.792,0,0,0-.088,36.031,19.795,19.795,0,0,0,25.9,21.726,19.79,19.79,0,0,0,34.459-.1,19.8,19.8,0,0,0,26.4-18.658,20.061,20.061,0,0,0-.222-2.971A19.791,19.791,0,0,0,681.32,2105.771Z"
                              transform="translate(-571.676 -2055.499)"
                              fill="#E94D4E"
                            />
                          </svg>{" "}
                          <div>
                            <img src={img3} />
                          </div>
                        </a>
                      </li>{" "}
                      <li>
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="109.644"
                            height="100"
                            viewBox="0 0 109.644 100"
                          >
                            <path
                              id="패스_1038"
                              data-name="패스 1038"
                              d="M681.32,2105.771a19.79,19.79,0,0,0-11.641-18.035,19.795,19.795,0,0,0-26.094-22.135,19.791,19.791,0,0,0-34.459-.1,19.781,19.781,0,0,0-25.809,22.232,19.792,19.792,0,0,0-.088,36.031,19.795,19.795,0,0,0,25.9,21.726,19.79,19.79,0,0,0,34.459-.1,19.8,19.8,0,0,0,26.4-18.658,20.061,20.061,0,0,0-.222-2.971A19.791,19.791,0,0,0,681.32,2105.771Z"
                              transform="translate(-571.676 -2055.499)"
                              fill="#2D2288"
                            />
                          </svg>{" "}
                          <div>
                            <img src={img5} />
                          </div>
                        </a>
                      </li>{" "}
                      <li>
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="109.644"
                            height="100"
                            viewBox="0 0 109.644 100"
                          >
                            <path
                              id="패스_1038"
                              data-name="패스 1038"
                              d="M681.32,2105.771a19.79,19.79,0,0,0-11.641-18.035,19.795,19.795,0,0,0-26.094-22.135,19.791,19.791,0,0,0-34.459-.1,19.781,19.781,0,0,0-25.809,22.232,19.792,19.792,0,0,0-.088,36.031,19.795,19.795,0,0,0,25.9,21.726,19.79,19.79,0,0,0,34.459-.1,19.8,19.8,0,0,0,26.4-18.658,20.061,20.061,0,0,0-.222-2.971A19.791,19.791,0,0,0,681.32,2105.771Z"
                              transform="translate(-571.676 -2055.499)"
                              fill="#23A339"
                            />
                          </svg>{" "}
                          <div>
                            <img src={img6} />
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div>
                      <span>
                        전체
                        <br />
                        보기
                      </span>
                      <FaPlusCircle />
                    </div>
                    {/* <span>전체보기</span> */}
                  </div>
                </div>
                <div className={my.shortcut}>
                  <ul className={my.shortcut_list}>
                    <li className={`${my.shortcut_item} ${my.n1}`}>
                      <a href="#n" className={my.shortcut_anchor}></a>
                    </li>
                    <li className={`${my.shortcut_item} ${my.n2}`}>
                      <a href="#n" className={my.shortcut_anchor}></a>
                    </li>
                    <li className={`${my.shortcut_item} ${my.n3}`}>
                      <a href="#n" className={my.shortcut_anchor}></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyClass;
