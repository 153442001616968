import { useEffect, useRef, useState, useCallback } from "react";
import notice from "../../styles/notice.module.css";
import { FiSearch } from "react-icons/fi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import CustomCateTable from "./detail/CustomCateTable";
import { CommonAxios } from "../CommonAxios";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Paging } from "../Pagination";
import QnaDivDatableList from "./detail/LessonDivTable";
const Qna = () => {
  const [filterView, setFilterview] = useState(false);

  const [qna, setQna] = useState([]);
  const [currentQna, setcurrentQna] = useState([]); // 보여 줄 공지사항
  const [count, setCount] = useState(0); //아이템 총 개수
  const [currentPage, setCurrentpage] = useState(1); //현재페이지
  const [PerPage] = useState(6); //페이지당 아이템 개수
  const [indexOfLastQnaDatas, setIndexOfLastQnaDatas] = useState(0);
  const [indexOfFirstQnaDatas, setIndexOfFirstQnaDatas] = useState(0);
  useEffect(() => {
    const setPage = () => {
      try {
        setCount(qna.length);
      } catch (e) {}
      setIndexOfLastQnaDatas(currentPage * PerPage);
      setIndexOfFirstQnaDatas(indexOfLastQnaDatas - PerPage);
      try {
        setcurrentQna(qna.slice(indexOfFirstQnaDatas, indexOfLastQnaDatas));
      } catch (e) {}
    };
    setPage();
  }, [
    count,
    currentPage,
    indexOfFirstQnaDatas,
    qna,
    indexOfLastQnaDatas,
    PerPage,
  ]);
  const setPage = (e) => {
    setCurrentpage(e);
  };
  useEffect(() => {
    let sendData = {
      search: "",
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_qna",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setQna(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  const click_list = () => {
    setFilterview(!filterView);
  };

  const dropRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        filterView &&
        dropRef.current &&
        !dropRef.current.contains(e.target)
      ) {
        setFilterview(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [filterView]);

  const [searchFilter, setSerarchFilter] = useState("전체");
  const [searchValue, setSearchValue] = useState("");
  const searchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const enterkey = (e) => {
    if (e.key === "Enter") {
      btn_search(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const btn_search = () => {
    let sendData = {
      search: searchValue,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_qna",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setQna(result.messageinfo.message);
        } else {
        }
      }
    );
  };
  return (
    <>
      <div id="wrapper">
        <div id="container">
          <div className={notice.notice}>
            <div className={notice.wrap}>
              {" "}
              <div className={notice.notice_box}>
                <div className={notice.notice_top}>
                  <ul>
                    <li className={notice.color_li}>
                      <Link to="/notice">공지사항</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/qna">자주묻는 질문</Link>
                    </li>
                  </ul>
                </div>
                <div className={notice.notice_content_box}>
                  <div className={notice.notice_content_search}>
                    <div className={notice.notice_search_wrap}>
                      <div
                        className={notice.notice_search_filter}
                        onClick={() => click_list()}
                      >
                        <span>{searchFilter}</span>
                        <span>{filterView && <TiArrowSortedUp />}</span>{" "}
                        <span>{!filterView && <TiArrowSortedDown />}</span>{" "}
                        <ul
                          className={
                            filterView
                              ? `${notice.notice_filter} ${notice.filter_view}`
                              : `${notice.notice_filter}`
                          }
                          ref={dropRef}
                        >
                          <li onClick={() => setSerarchFilter("전체")}>전체</li>
                        </ul>
                      </div>

                      <div className={notice.notice_search_box}>
                        <div className={notice.notice_search_inp}>
                          <input
                            type="text"
                            onChange={searchChange}
                            onKeyPress={enterkey}
                            value={searchValue}
                          />
                        </div>
                        <button
                          className={notice.notcie_search_button}
                          onClick={btn_search}
                        >
                          <FiSearch />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={notice.notice_table_wrap} id="qna_table">
                    <div className={notice.qna_table_wrap}>
                      <div className={notice.qna_table}>
                        <div className={notice.qna_table_hd}>
                          <div className={notice.qna_table_hd_ca}>분류</div>
                          <div className={notice.qna_table_hd_div}>구분</div>
                          <div className={notice.qna_table_hd_tit}>제목</div>
                        </div>
                        <QnaDivDatableList datas={currentQna} />
                        {/* {!isEmptyArr() ? (
                <LessonDivTable datas={currentQna} />
              ) : (
                <NoQna />
              )} */}
                      </div>
                      <div className={notice.qna_pag}>
                        <Paging
                          page={currentPage}
                          perPage={PerPage}
                          count={count}
                          setPage={setPage}
                        />
                      </div>
                    </div>
                    {/* <CustomCateTable
                      columns={TableColumn}
                      data={arrayList}
                      options={options}
                 
                      arrayList={arrayList}
                 
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Qna;
