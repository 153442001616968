import client from "./client";

// 회원 가입
export const signUp = ({ signName, email, password }) =>
  client.post("/api/signup_user", {
    signName,
    email,
    password,
  });

// 로그인 후 유저 정보 불러오기
export const login = ({ email, password }) =>
  client.post("/api/signin", { email, password });

export const getUserInfo = ({ no }) => client.post("/api/user_info", { no });

export const updateUsers = ({
  no,
  name,
  nickname,
  mobile,
  filename,
  image,
  beforeImage,
}) =>
  client.post("/api/update_user", {
    no,
    name,
    nickname,
    mobile,
    filename,
    image,
    beforeImage,
  });

export const updateStudent = ({
  no,
  exPass,
  chPass,
  filename,
  image,
  beforeImage,
}) =>
  client.post("/api/update_student_info_width_image", {
    no,
    exPass,
    chPass,
    filename,
    image,
    beforeImage,
  });
export const snslogin = ({
  id,
  name,
  email,
  mobile,
  image,
  type,
  nickname,
  mode,
}) =>
  client.post("/api/sns_signin", {
    id,
    name,
    mobile,
    email,
    image,
    type,
    nickname,
    mode,
  });
export const adminInfoD = ({ no }) =>
  client.post("/api/admin_info_detail", {
    no,
  });
export const creatorClass = ({
  no,
  class_nickname,
  class_title,
  class_hash_1,
  class_hash_2,
  class_hash_3,
  class_hash_4,
  class_hash_5,
  class_hash_6,
  class_date,
  category_one,
  category_two,
  class_type,
  filename,
  image,
}) =>
  client.post("/api/insert_class", {
    no,
    class_nickname,
    class_title,
    class_hash_1,
    class_hash_2,
    class_hash_3,
    class_hash_4,
    class_hash_5,
    class_hash_6,
    class_date,
    category_one,
    category_two,
    class_type,
    filename,
    image,
  });
