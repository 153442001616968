import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import Main from "./pages/Main";

import NoLayout from "./layout/NoLayout";

import "../src/styles/common.css";
import "../src/styles/layout.css";
import "../src/styles/default.css";
import "../src/styles/font.css";
// import "./styles/common.css";
// import "./styles/layout.css";
// import "./styles/default.css";
// import "./styles/font.css";
import ChannelService from "./ChannelService";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import ListPage from "./pages/ListPage";
import MyClassPage from "./pages/MyClassPage";
import MonthLessonPage from "./pages/MonthLessonPage";
import Test from "./pages/Test";
import NoticePage from "./pages/NoticePage";
import Notice from "./pages/NoticePage";
import QnaPage from "./pages/QnaPage";
import Info from "./components/main/Info";
import InfoPage from "./pages/InfoPage";
import NoticeViewPage from "./pages/NoticeViewPage";

function App() {
  useEffect(() => {
    ChannelService.loadScript();

    //3. 부트하기
    ChannelService.boot({
      pluginKey: "d5c3dd7d-f725-4c12-97cb-f593028eb153",
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} /> {/* </Route> */}
        <Route path="/login" element={<Login />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="" element={<NoLayout />}>
          <Route path="/list" element={<ListPage />} />
          <Route path="/myclass" element={<MyClassPage />} />
          <Route path="/month-lesson" element={<MonthLessonPage />} />{" "}
          <Route path="/notice" element={<NoticePage />} />{" "}
          <Route path="/notice/:id" element={<NoticeViewPage />} />{" "}
          <Route path="/qna" element={<QnaPage />} />
        </Route>{" "}
        <Route path="/test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
