import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { CommonAxios } from "../components/CommonAxios";
// import ListWrap from "../components/list/ListWrap";
import MonthLessonWrap from "../components/Month/MonthLessonWrap";

const MonthLessonPage = () => {
  return (
    <>
      <MonthLessonWrap />
    </>
  );
};

export default MonthLessonPage;
