import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { FcEditImage } from "react-icons/fc";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/notice.module.css";
import Swal from "sweetalert2";
import { CommonAxios } from "../../CommonAxios";
// import { getTextTotal, textOverCut } from "../../lib/stringLenghts";
// import { textReplace } from "../../lib/textReplace";
import { useNavigate, useParams } from "react-router-dom";

import {
  BiSolidUpArrow,
  BiSolidDownArrow,
  BiSolidLeftArrow,
  BiSolidRightArrow,
} from "react-icons/bi";
function CustomCateTable({
  columns,
  data,
  options,
  setOptions,
  arrayList,
  setArrayList,
  setSendList,
  linkData,
  setOpen,
  type,
  countRef,
  cntResultRef,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    selectedFlatRows,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 6 } },
    useSortBy,
    usePagination,
    useRowSelect
    // ,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",

    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),

    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );
  const sendData = (data) => {
    linkData(data);
  };
  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );
  const navigate = useNavigate();
  useEffect(() => {}, [pageIndex]);

  // const btn_delete = () => {
  //   let dataArr = [];

  //   let view = 0;
  //   for (let i = 0; i < selectedFlatRows.length; i++) {
  //     if (selectedFlatRows[i].original.no) {
  //       dataArr.push(selectedFlatRows[i].original.no);
  //     }
  //   }
  //   console.log(dataArr);
  // };

  const movePage = (data) => {
    navigate(`/notice/${data}`);
  };
  return (
    <>
      <table
        className={styles.notice_table}
        {...getTableProps()}
        style={{ margin: "auto" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidDownArrow style={{ marginBottom: "5px" }} />
                        </i>
                      ) : (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidUpArrow style={{ marginBottom: "5px" }} />{" "}
                        </i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <colgroup>
          <col span="1" style={{ width: "10%" }} />
          <col span="1" style={{ width: "50%" }} />
          <col span="1" style={{ width: "10%" }} />
        </colgroup>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="table_row"
                onClick={() => {
                  movePage(row.original.no);
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    style={{ cursor: "pointer" }}
                    {...cell.getCellProps()}
                    // onClick={() =>
                    //   viewFun(
                    //     row.original.no,
                    //     row.original.category_name,
                    //     row.original.category_img,
                    //     row.original.category_open
                    //   )
                    // }
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.table_pagination_delete}>
        {" "}
        <div className={styles.table_pagination_delete_box}>
          {/* {pageIndex >= 10 && ( */}
          <button onClick={() => gotoPage(Math.floor(pageIndex / 1) * 1 - 1)}>
            <BiSolidLeftArrow />
          </button>
          {/* )} */}
          {pageOptions.map((page, index) => {
            if (pageIndex < 10 && index < 10) {
              return (
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  style={{
                    color: pageIndex === index ? "#f5a21f" : "#000",
                    margin: "0 10px",
                  }}
                >
                  {index + 1}
                </button>
              );
            } else if (pageIndex >= 10) {
              const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
              const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
              const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
              if (index >= startPageIndex && index < endPageIndex) {
                return (
                  <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    style={{
                      color: pageIndex === index ? "#f5a21f" : "#000",
                      margin: "0 10px",
                    }}
                  >
                    {index + 1}
                  </button>
                );
              }
            }
            return null;
          })}
          {/* {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && ( */}
          <button onClick={() => gotoPage(Math.floor(pageIndex / 1 + 1) * 1)}>
            {/* <button onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}> */}
            <BiSolidRightArrow />
          </button>
          {/* )} */}
        </div>
      </div>
      {/* <div onClick={btn_delete}>삭제</div> */}
    </>
  );
}

export default CustomCateTable;
