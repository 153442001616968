import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
// import SuccessAlert from "../components/alert/Alert";
// import Footer from "../components/footer/Footer";
// import MobileFix from "../components/mobileFix/MobileFix";

const NoLayout = () => {
  return (
    <>
      {/* <SuccesAlert */}
      <Header />

      <Outlet />
      {/* <MobileFix /> */}
      {/* <Footer /> */}
    </>
  );
};

export default NoLayout;
