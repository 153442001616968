import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { CommonAxios } from "../components/CommonAxios";
import MyClass from "../components/myclass/MyClass";

const MyClassPage = () => {
  return (
    <>
      <MyClass />
    </>
  );
};

export default MyClassPage;
