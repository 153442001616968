import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { FcEditImage } from "react-icons/fc";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/notice.module.css";
import Swal from "sweetalert2";
import { CommonAxios } from "../../CommonAxios";
// import { getTextTotal, textOverCut } from "../../lib/stringLenghts";
// import { textReplace } from "../../lib/textReplace";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";

import {
  BiSolidUpArrow,
  BiSolidDownArrow,
  BiSolidLeftArrow,
  BiSolidRightArrow,
} from "react-icons/bi";
function NoticeViewDetail({ data }) {
  return (
    <>
      <div className={styles.notice_view_wrap}>
        <div className={styles.notice_view_title}>
          <p>{data.notice_title}</p>{" "}
          <span>
            <Moment format="YYYY.MM.DD">{data.notice_date}</Moment>
          </span>
        </div>
        <div className={styles.notice_view_content}>
          <div dangerouslySetInnerHTML={{ __html: data.notice_content }}></div>
        </div>
      </div>
    </>
  );
}

export default NoticeViewDetail;
