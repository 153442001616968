import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import notice from "../../../styles/notice.module.css";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
const LessonDivTableList = ({ data }) => {
  const {
    // ROWNUM,
    no,
    qna_title,
    qna_content,

    qna_date,
    qna_division,
    qna_category,
  } = data;
  const [viewContent, setViewContent] = useState(false);

  const dispatch = useDispatch();
  const sendData = {
    no: no,
  };

  const onClickTitle = () => {
    setViewContent(!viewContent);
  };

  const onClickDeleteQna = () => {};

  // const transDate = qna_date.substr(0, 10);
  // const date = transDate.replaceAll("-", ".");

  const ContentDiv = () => {
    return (
      <>
        <div className={`${notice.qna_table_view_cont}`}>
          <div style={{ display: "flex" }}>
            <div className={notice.qna_table_view_ca}></div>

            <div
              style={{ paddingRight: "10px" }}
              dangerouslySetInnerHTML={{ __html: qna_content }}
            ></div>
          </div>
        </div>
      </>
    );
  };

  const LockContent = () => {
    return <ContentDiv />;
  };
  const divi = (data) => {
    if (data === 1) {
      return "교재구입";
    }
    if (data === 2) {
      return "환불";
    }
    if (data === 3) {
      return "수강신청";
    }
    if (data === 4) {
      return "강좌신청";
    }
    if (data === 5) {
      return "교재배송";
    }
    if (data === 6) {
      return "모바일러닝";
    }
  };
  return (
    <>
      <div
        className={
          !viewContent
            ? notice.qna_table_tb
            : `${notice.qna_table_tb} ${notice.qna_table_view}`
        }
        onClick={onClickTitle}
      >
        <div className={notice.qna_table_tb_ca}>
          <span>{qna_category === 2 ? "Q&A" : ""}</span>
        </div>
        <div className={notice.qna_table_tb_div}>
          <span>{divi(qna_division)}</span>
        </div>
        <div className={notice.qna_table_td_tit}>{qna_title}</div>
        <div className={notice.qna_up_down}>
          {" "}
          {!viewContent && <TiArrowSortedDown />}{" "}
          {viewContent && <TiArrowSortedUp />}
        </div>
      </div>
      {/* 클릭시 확장 div */}
      {viewContent && <LockContent />}
    </>
  );
};

export default LessonDivTableList;
