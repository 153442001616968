import "../styles/notice.module.css";
import Pagination from "react-js-pagination";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import {
  BiSolidUpArrow,
  BiSolidDownArrow,
  BiSolidLeftArrow,
  BiSolidRightArrow,
} from "react-icons/bi";
const style = {
  marginTop: "2%",
  marginLeft: "38%",
  marginRight: "38%",
};
const style2 = {
  width: "200px",
  height: "45px",
};

export const Paging = ({ page, perPage, count, setPage }) => {
  return (
    <>
      <Pagination
        activePage={page}
        itemsCountPerPage={perPage}
        totalItemsCount={count}
        pageRangeDisplayed={4}
        prevPageText={<BiSolidLeftArrow />}
        nextPageText={<BiSolidRightArrow />}
        onChange={setPage}
      />
    </>
  );
};

// export const SkeletonPaging = () => {
//   return (
//     <div style={style}>
//       <Skeleton style={style2} />
//     </div>
//   );
// };
