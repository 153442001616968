function Test() {
  return (
    <>
      <iframe
        src="https://ddot.kr/kiz/202203/202203.html"
        width="100%"
        height="1000px"
        allowFullScreen
      ></iframe>
    </>
  );
}

export default Test;
