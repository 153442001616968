import { useNavigate } from "react-router-dom";

const SuccessAlert = ({ content, title, setSuccessAlert }) => {
  const btn_alert_yes = () => {
    setSuccessAlert(false);
  };
  return (
    <>
      {" "}
      <div className="alert_div_back_back"></div>
      <div className="alert_div_back">
        <div className="alert_div_wrap">
          <div className="alert_div_inner">
            <div className="alert_div_title">{title}</div>
            <div className="alert_div_content">{content}</div>
            <button className="btn_alert" onClick={btn_alert_yes}>
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const MoveAlert = ({ content, setMoveAlert, movePage }) => {
  const navigate = useNavigate();
  const btn_alert_yes = () => {
    navigate(movePage);
    setMoveAlert(false);
  };
  return (
    <>
      {" "}
      <div className="alert_div_back_back"></div>
      <div className="alert_div_back">
        <div className="alert_div_wrap">
          <div className="alert_div_inner">
            <div className="alert_div_content">{content}</div>
            <button className="btn_alert" onClick={btn_alert_yes}>
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const MoveAlert2 = ({
  setMoveAlert,
  movePage,
  moveTitle,
  moveData1,
  moveData2,
}) => {
  const navigate = useNavigate();
  const btn_alert_yes = () => {
    navigate(movePage);
    setMoveAlert(false);
  };
  return (
    <>
      {" "}
      <div className="alert_div_back_back"></div>
      <div className="alert_div_back">
        <div className="alert_div_wrap3">
          <div className="alert_div_inner">
            <div className="alert_div_title">{moveTitle}</div>
            <div className="alert_div_content">
              <p>
                투자금액 {moveData1}만원 (이자 {moveData2}만원/연/세전/최대)
              </p>
              <span>
                투자 신청이 완료되었습니다. <br />
                감사합니다.{" "}
              </span>
            </div>
            <button className="btn_alert" onClick={btn_alert_yes}>
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const WarringAlert = ({ content, setWarringAlert }) => {
  const btn_alert_yes = () => {
    setWarringAlert(false);
  };
  return (
    <>
      <div className="alert_div_back_back"></div>
      <div className="alert_div_back">
        <div className="alert_div_wrap">
          <div className="alert_div_inner">
            <div className="alert_div_content">{content}</div>
            <button className="btn_alert" onClick={btn_alert_yes}>
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export { SuccessAlert, MoveAlert, WarringAlert, MoveAlert2 };
