import lesson from "../../styles/lesson.module.css";

import { useState } from "react";
import ListContent from "./detail/ListContent";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/images/science_1.png";
import img2 from "../../assets/images/school_1.png";
import img3 from "../../assets/images/science_1.png";
import { CommonAxios } from "../CommonAxios";
const MonthLessonWrap = () => {
  const [data2, setData2] = useState([]);
  const [twoSelect, setTwoSelect] = useState(false);
  const [threeSelect, setThreeSelect] = useState(false);
  const [rightColor, setRightColor] = useState(1);
  const [select, setSelect] = useState(0);
  const [sw, setSw] = useState(false);
  const yearClick = (data) => {
    setRightColor(data);
  };

  useEffect(() => {
    let sendData = {};

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_month_lesson",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData2(result.messageinfo.message);

          if (result.messageinfo.message.length % 3 === 1) {
            setSelect(2);
          } else if (result.messageinfo.message.length % 3 === 2) {
            setSelect(1);
          } else {
            select(0);
          }

          setSw(true);
          // if(result.messageinfo.message.length%3)
        } else {
        }
      }
    );
    // setSelect("one");
  }, []);

  const selectItem = (data) => {
    setSelect(data);
  };

  const ListItem = ({ data }) => {
    return (
      <li>
        <div className={lesson.lesson_div}>
          <a href={data.class_link}>
            <div className={lesson.lesson_li_imgbox}>
              <img src={`/class/${data.no}/${data.class_img}`} />
            </div>
            <div className={lesson.lesson_li_title}>
              <span>
                {data.project_title}-{data.category_title}
              </span>
            </div>
            <div className={lesson.lesson_li_part}>
              {data.class_step_name === 1 && (
                <ul>
                  <li>
                    <span className={lesson.lesson_part_blue}>1차시</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {data.class_step_one}
                    </span>
                  </li>{" "}
                  <li>
                    {" "}
                    <span className={lesson.lesson_part_blue}>2차시</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {" "}
                      {data.class_step_two}
                    </span>
                  </li>{" "}
                  <li>
                    {" "}
                    <span className={lesson.lesson_part_blue}>3차시</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {data.class_step_three}
                    </span>
                  </li>{" "}
                  <li>
                    {" "}
                    <span className={lesson.lesson_part_blue}>4차시</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {" "}
                      {data.class_step_four}
                    </span>
                  </li>
                </ul>
              )}{" "}
              {data.class_step_name === 2 && (
                <ul>
                  <li>
                    <span className={lesson.lesson_part_blue}>파트1</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {data.class_step_one}
                    </span>
                  </li>{" "}
                  <li>
                    {" "}
                    <span className={lesson.lesson_part_blue}>파트2</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {" "}
                      {data.class_step_two}
                    </span>
                  </li>{" "}
                  <li>
                    {" "}
                    <span className={lesson.lesson_part_blue}>파트3</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {data.class_step_three}
                    </span>
                  </li>{" "}
                  <li>
                    {" "}
                    <span className={lesson.lesson_part_blue}>파트4</span>{" "}
                    <span className={lesson.lesson_part_text}>
                      {" "}
                      {data.class_step_four}
                    </span>
                  </li>
                </ul>
              )}
            </div>{" "}
          </a>{" "}
        </div>
      </li>
    );
  };

  const ListItem2 = ({}) => {
    return (
      <li>
        <div className={lesson.lesson_div}></div>
      </li>
    );
  };
  return (
    <>
      <div id="wrapper">
        <div id="container">
          <div className={lesson.lesson}>
            <div className={lesson.wrap}>
              <div className={lesson.lesson_box}>
                {" "}
                <div className={lesson.lesson_inner}>
                  <ul className={lesson.lesson_ul}>
                    {sw &&
                      data2.length > 0 &&
                      data2.map((data) => {
                        return <ListItem data={data} />;
                      })}
                    {sw && select === 1 && <ListItem2 />}
                    {sw && select === 2 && (
                      <>
                        <ListItem2 /> <ListItem2 />{" "}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthLessonWrap;
