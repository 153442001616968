import { combineReducers } from "redux";
import user from "./user";
import loading from "./loading";
import invest from "./invest";
const rootReducer = combineReducers({
  loading,
  user,
  invest,
});

export default rootReducer;
