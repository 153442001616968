import { useEffect, useRef, useState, useCallback } from "react";
import main from "../../styles/main.module.css";
import { FiSearch } from "react-icons/fi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { CommonAxios } from "../CommonAxios";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Paging } from "../Pagination";
import { IoMdLock } from "react-icons/io";
import { BsCheckCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import DaumPostcode from "react-daum-postcode";
import { MoveAlert, SuccessAlert } from "../alert/Alert";
import { FaMobile } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaCircleXmark } from "react-icons/fa6";
import { getUsers } from "../../modules/user";

const Info = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);
  useEffect(() => {
    let sendData = {
      no: user.no,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_info_user",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setLoad(true);
          if (result.messageinfo.message) {
            setName(result.messageinfo.message[0].user_name);
            if (result.messageinfo.message[0].user_birthday) {
              setYear(
                result.messageinfo.message[0].user_birthday.split("-")[0]
              );
              setMon(result.messageinfo.message[0].user_birthday.split("-")[1]);
              setDay(result.messageinfo.message[0].user_birthday.split("-")[2]);
            }
            setMobile(result.messageinfo.message[0].user_mobile);
            setEmail(result.messageinfo.message[0].user_email);
            setCode(result.messageinfo.message[0].user_road_code);
            setRoad(result.messageinfo.message[0].user_road);
            setAddressDetail(result.messageinfo.message[0].user_road_detail);
            if (result.messageinfo.message[0].user_agree1) {
              setAg_1(true);
            }
            if (result.messageinfo.message[0].user_agree2) {
              setAg_2(true);
            }
            if (result.messageinfo.message[0].user_agree3) {
              setAg_3(true);
            }
          }
          // setQna(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  const [ag_1, setAg_1] = useState(false);
  const [ag_2, setAg_2] = useState(false);
  const [ag_3, setAg_3] = useState(false);

  // 패스워드 변경
  const password_test =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,16}$/;

  const [usePassword1, setUsePassword1] = useState("");
  const [usePassword2, setUsePassword2] = useState("");
  const [changePassColor, setChangePassColor] = useState(true); //패스워드 오류 메세지
  const [changePass2Color, setChangePass2Color] = useState(true); //패스워드 2차 확인 오류 메세지

  const passChange1 = useCallback((e) => {
    setUsePassword1(e.target.value);
    if (e.target.value !== "" && !password_test.test(e.target.value)) {
      setChangePassColor(false);
    } else if (e.target.value === "") {
      setChangePassColor(true);
    } else {
      setChangePassColor(true);
    }
  }, []);

  const passChange2 = useCallback(
    (e) => {
      setUsePassword2(e.target.value);
      if (usePassword1) {
        if (e.target.value !== "" && e.target.value !== usePassword1) {
          setChangePass2Color(false);
        } else {
          setChangePass2Color(true);
        }
      }
    },
    [usePassword1]
  );
  // 이름 생일 휴대폰 이메일
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [mon, setMon] = useState("");
  const [day, setDay] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const email_test =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  const [emailError, setEmailError] = useState(true);
  const [mError, setMError] = useState(true);

  const onChangeYear = useCallback((e) => {
    setYear(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
  }, []);
  const onChangeMon = useCallback((e) => {
    setMon(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
  }, []);
  const onChangeDay = useCallback((e) => {
    setDay(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
  }, []);
  const onChangeM = useCallback((e) => {
    setMobile(e.target.value);

    if (
      e.target.value !== "" &&
      !/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(e.target.value)
    ) {
      setMError(false);
    } else if (e.target.value === "") {
      setMError(true);
    } else {
      setMError(true);
    }
  }, []);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);

    if (e.target.value !== "" && !email_test.test(e.target.value)) {
      setEmailError(false);
    } else if (e.target.value === "") {
      setEmailError(true);
    } else {
      setEmailError(true);
    }
  }, []);

  const mobileBlur = () => {};
  // 주소 변경
  const [isOpen, setIsOpen] = useState(false);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: "1000",
    },
    content: {
      left: "0",
      margin: "auto",
      width: "400px",
      height: "300px",
      padding: "0",
      overflow: "",
      // overflow: "hidden",
    },
  };
  const [code, setCode] = useState("");
  const [road, setRoad] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    // if (data.addressType === "R") {
    //   if (data.bname !== "") {
    //     extraAddress += data.bname;
    //   }
    //   if (data.buildingName !== "") {
    //     extraAddress +=
    //       extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
    //   }
    //   fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    // }
    // setWriteInfo({ ...writeInfo, address: fullAddress });
    setIsOpen(false);
    setCode(data.zonecode);
    setRoad(data.address);
  };
  const change_detail = useCallback((e) => {
    setAddressDetail(e.target.value);
  }, []);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successCont, setsuccessCont] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [moveAlert, setMoveAlert] = useState(false);
  const btn_update = () => {
    if (!changePassColor || !changePass2Color) {
      setSuccessTitle("비밀번호 확인");
      setsuccessCont("비밀번호를 다시 확인해주세요.");
      setSuccessAlert(true);
      return;
    }
    if (!name || !year || !mon || !day) {
      setSuccessTitle("빈칸 확인!");
      setsuccessCont("이름과 생년월일은 필수입니다.");
      setSuccessAlert(true);
      return;
    }
    if (!mError || !emailError) {
      setSuccessTitle("휴대번호 및 이메일 확인");
      setsuccessCont("형식에 맞게 입력해주세요.");
      setSuccessAlert(true);
      return;
    }
    let sendData = {
      check: user.ur_check,
      no: user.no,
      password: usePassword1 ? usePassword1 : "",
      user_name: name,
      user_birthday: `${year}-${mon}-${day}`,
      user_mobile: mobile ? mobile : "",
      user_email: email ? email : "",
      user_road: road ? road : "",
      user_road_detail: addressDetail ? addressDetail : "",
      user_road_code: code ? code : "",
      user_agree1: ag_1 ? "1" : "0",
      user_agree2: ag_2 ? "1" : "0",
      user_agree3: ag_3 ? "1" : "0",
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/update_user",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          dispatch(getUsers(result.messageinfo.message));
          setMoveAlert(true);
        }
      }
    );
  };

  const movePageWhere = () => {
    return "/list";
  };

  return (
    <>
      {load && (
        <>
          {" "}
          {successAlert && (
            <SuccessAlert
              content={successCont}
              title={successTitle}
              setSuccessAlert={setSuccessAlert}
            />
          )}
          {moveAlert && (
            <MoveAlert
              content={"수정 완료"}
              setMoveAlert={setMoveAlert}
              movePage={movePageWhere()}
            />
          )}
          <div id="wrapper">
            <div id="container">
              <div className={main.info}>
                <div className={main.wrap}>
                  {" "}
                  <div className={main.info_box}>
                    <div className={main.info_hd}>
                      <span>회원정보 수정</span>
                    </div>{" "}
                    <div className={main.info_one}>
                      <ul>
                        <li className={main.info_one_li}>ID : {user.ur_id}</li>
                        <li className={main.info_one_li}>
                          <input
                            type="password"
                            placeholder="비밀번호 (변경 시에만 입력)"
                            onChange={passChange1}
                            value={usePassword1}
                          />
                        </li>
                        <li className={main.info_one_li}>
                          {" "}
                          <input
                            type="password"
                            placeholder="비밀번호 확인"
                            onChange={passChange2}
                            value={usePassword2}
                          />
                        </li>
                      </ul>
                    </div>{" "}
                    <p
                      className={
                        !changePassColor
                          ? `${main.info_ex} ${main.info_ex_view}`
                          : `${main.info_ex}`
                      }
                    >
                      <IoMdLock /> 비밀번호는 영문,숫자,특수문자를 포함하여
                      8~16자로 입력해주세요.
                    </p>{" "}
                    {!changePass2Color && (
                      <p
                        className={
                          !changePass2Color
                            ? `${main.info_ex3} ${main.info_ex3_view}`
                            : `${main.info_ex3}`
                        }
                      >
                        <div>
                          <FaCircleXmark /> 변경하실 비밀번호를 다시
                          확인해주세요.
                        </div>
                      </p>
                    )}
                    <div className={main.info_two}>
                      <ul>
                        <li className={main.info_two_li}>
                          {" "}
                          <input type="text" placeholder="이름" value={name} />
                        </li>
                        <li className={main.info_two_li}>
                          <div>
                            {" "}
                            <input
                              type="text"
                              placeholder="년"
                              onChange={onChangeYear}
                              value={year}
                              maxLength={4}
                            />
                          </div>
                          <div>
                            {" "}
                            <input
                              type="text"
                              placeholder="월"
                              onChange={onChangeMon}
                              value={mon}
                              maxLength={2}
                            />
                          </div>
                          <div>
                            {" "}
                            <input
                              type="text"
                              placeholder="일"
                              onChange={onChangeDay}
                              value={day}
                              maxLength={2}
                            />
                          </div>
                        </li>
                        <li className={main.info_two_li}>
                          {" "}
                          <input
                            type="text"
                            placeholder="휴대전화 번호 하이픈(-) 포함"
                            value={mobile}
                            onChange={onChangeM}
                          />
                        </li>{" "}
                        <li className={main.info_two_li}>
                          {" "}
                          <input
                            type="text"
                            placeholder="이메일 주소"
                            onChange={onChangeEmail}
                            value={email}
                          />
                        </li>
                      </ul>
                    </div>{" "}
                    <p
                      className={
                        !mError || !emailError
                          ? `${main.info_ex2} ${main.info_ex2_view}`
                          : `${main.info_ex2}`
                      }
                    >
                      {!mError && (
                        <div>
                          <FaMobile /> 휴대전화 번호 형식이 올바르지 않습니다.
                        </div>
                      )}{" "}
                      {!emailError && (
                        <div>
                          <MdEmail /> 이메일 형식이 올바르지 않습니다.
                        </div>
                      )}
                    </p>
                    <div className={main.info_three}>
                      {" "}
                      <Modal
                        isOpen={isOpen}
                        ariaHideApp={false}
                        style={customStyles}
                      >
                        <div className={`${main.info_address_btn_box}`}>
                          <div
                            className={`${main.info_address_btn}`}
                            onClick={() => setIsOpen(false)}
                          >
                            {" "}
                            닫기
                          </div>
                        </div>
                        <DaumPostcode
                          onComplete={handleComplete}
                          height="100%"
                        />
                      </Modal>
                      <ul>
                        <li className={main.info_three_li}>
                          <div>
                            {" "}
                            <input
                              type="text"
                              placeholder="우편번호"
                              disabled
                              value={code}
                            />
                          </div>
                          <div onClick={() => setIsOpen(true)}>
                            우편번호 검색
                          </div>
                        </li>
                        <li className={main.info_three_li}>
                          {" "}
                          <input
                            type="text"
                            placeholder="주소"
                            disabled
                            value={road}
                          />
                        </li>
                        <li className={main.info_three_li}>
                          {" "}
                          <input
                            type="text"
                            placeholder="상세 주소"
                            onChange={change_detail}
                            value={addressDetail}
                          />
                        </li>
                      </ul>
                    </div>{" "}
                    <div className={main.info_four}>
                      <ul>
                        <li
                          className={main.info_four_li}
                          onClick={() => setAg_1(!ag_1)}
                        >
                          <span>이용약관</span>
                          <BsCheckCircleFill
                            className={
                              ag_1
                                ? `${main.four_svg}`
                                : `${main.four_svg} ${main.click}`
                            }
                          />
                        </li>
                        <li
                          className={main.info_four_li}
                          onClick={() => setAg_2(!ag_2)}
                        >
                          {" "}
                          <span>개인 정보 수집/이용 안내</span>
                          <BsCheckCircleFill
                            className={
                              ag_2
                                ? `${main.four_svg}`
                                : `${main.four_svg} ${main.click}`
                            }
                          />
                        </li>
                        <li
                          className={main.info_four_li}
                          onClick={() => setAg_3(!ag_3)}
                        >
                          {" "}
                          <span>이벤트 등 프로모션 알림 수신</span>
                          <BsCheckCircleFill
                            className={
                              ag_3
                                ? `${main.four_svg}`
                                : `${main.four_svg} ${main.click}`
                            }
                          />
                        </li>
                      </ul>
                    </div>{" "}
                    <div className={main.info_ft}>
                      <span onClick={btn_update}>수정 완료</span>
                    </div>{" "}
                    <div className={main.info_ft_ex}>
                      {" "}
                      <span>
                        <Link to="/list">강의 목록으로</Link>
                      </span>
                      <span>회원 탈퇴</span>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Info;
