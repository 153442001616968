import React from "react";
import { createRoot } from "react-dom/client";
// import "./index.css";
import rootReducer from "./modules";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { getUserInfo, tempSetUser } from "./modules/user";
import ReduxThunk from "redux-thunk";
import { decrypt } from "./lib/api/tokenCrypto";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk))
  // compose(applyMiddleware(ReduxThunk))
);

function loadUser() {
  try {
    // const user = localStorage.getItem("serveInfo");
    const user = decrypt(
      localStorage.getItem("digibooktoken"),
      "digibooktoken"
    );
    if (!user) return;

    // 로컬에 있는 기존 정보 불러와서 상태 user값에 담고
    store.dispatch(tempSetUser(user));

    // 유저 정보를 불러옴
    const sendData = {
      no: user.no,
    };

    store.dispatch(getUserInfo(sendData));
  } catch (e) {
    // console.log("로그인 안 됨");
  }
}

loadUser();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Provider? 하나의 컴포넌트로 REACT 컴포넌트를 감싸줌으로써 redux의 store에 접근할 수 있게 해줌
//store ? 애플리케이션의 state(상태)를 저장하는 곳
