import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { CommonAxios } from "../components/CommonAxios";
import Notice from "../components/notice/Notice";

const NoticePage = () => {
  return (
    <>
      <Notice />
    </>
  );
};

export default NoticePage;
