import list from "../../../styles/list.module.css";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
  Grid,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState, useEffect, useRef } from "react";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import img1 from "../../../assets/images/science_1.png";
import img2 from "../../../assets/images/science_2.png";
import img3 from "../../../assets/images/science_3.png";
import img4 from "../../../assets/images/science_4.png";
import img5 from "../../../assets/images/science_5.png";
import img6 from "../../../assets/images/science_6.png";
import img7 from "../../../assets/images/science_7.png";
import img7_b from "../../../assets/images/science_7_back.png";
import img8 from "../../../assets/images/school_1.png";
import img8_b from "../../../assets/images/school_1_back.png";
const ListContent = ({ select, rightColor }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const data = [
    // { img: img1, link: "https://ddot.kr/202111.html", type: "2022" },
    // { img: img2, link: "https://ddot.kr/202112.html", hover: "황소호" },
    // { img: img3, link: "https://ddot.kr/202201.html", hover: "쌍둥이호" },
    // { img: img4, link: "https://ddot.kr/202202.html", hover: "게호" },
    {
      img: img7,
      link: "https://ddot.kr/202109/202109.html",
      type: 2,
      back: img7_b,
    },
  ];
  const data1 = [
    {
      img: img8,
      link: "https://ddot.kr/2-1/2-1.html",
      type: 2,
      back: img8_b,
    },
    // { img: img2, link: "https://ddot.kr/202112.html", hover: "황소호" },
    // { img: img3, link: "https://ddot.kr/202201.html", hover: "쌍둥이호" },
    // { img: img4, link: "https://ddot.kr/202202.html", hover: "게호" },
    // { img: img5, link: "https://ddot.kr/202109/202109.html", hover: "사자호" },
  ];

  const ListItem = ({ data }) => {
    return (
      <>
        <div className={list.list_item_img}>
          <a href={data.link} className={list.list_item_link}>
            <img src={data.img} className={list.list_item_image} />
            <img src={data.back} className={list.list_item_back} />
            {/* <div className={list.list_hover}>
              <span>{data.hover}</span>
            </div> */}
          </a>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={list.list_inner}>
        <div className={list.list_content_box}>
          <div className={list.list_content}>
            <div className="common_list_swiper">
              <Swiper
                className="mySwiper"
                slidesPerView={3}
                spaceBetween={30}
                grid={{
                  rows: 2,
                  fill: "rows",
                }}
                ref={setSwiper}
                {...parmss}
                style={{ position: "relative" }}
                modules={[Navigation, Grid, Pagination]}
                breakpoints={{
                  1710: {
                    spaceBetween: 70,
                  },
                }}
              >
                {select === "one" &&
                  data.map((data, index) => {
                    if (rightColor === data.type) {
                      return (
                        <SwiperSlide>
                          <div>
                            <ListItem data={data} />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })}
                {select === "two" &&
                  data1.map((data, index) => {
                    if (rightColor === data.type) {
                      return (
                        <SwiperSlide>
                          <div>
                            <ListItem data={data} />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })}
              </Swiper>
            </div>
            <ButtonPrev1
              ref={prevRef}

              // style={{ width: "100px", height: "100px", background: "blue" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BiSolidLeftArrow />
              </div>
            </ButtonPrev1>{" "}
            {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
            <ButtonNext1 ref={nextRef}>
              {" "}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <BiSolidRightArrow />
              </div>
            </ButtonNext1>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
const ButtonPrev1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 100px;
  color: #2d2288;
  top: 50%;
  left: -100px;
  margin-top: -50px;
  font-size: 40px;
  @media screen and (max-width: 1710px) {
    left: -60px;
  }
`;
const ButtonNext1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 100px;
  color: #2d2288;
  top: 50%;
  right: -100px;
  margin-top: -50px;
  font-size: 40px;
  @media screen and (max-width: 1710px) {
    right: -60px;
  }
`;

export default ListContent;
