import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { CommonAxios } from "../components/CommonAxios";
import Info from "../components/main/Info";

const InfoPage = () => {
  return (
    <>
      <Info />
    </>
  );
};

export default InfoPage;
