import React, { useState, useEffect, useRef, useCallback } from "react";

import logo from "../logo.svg";
// import "../App.css";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import axios from "axios";
import { CommonAxios } from "../components/CommonAxios";
import Modal from "../lib/Modal";
import log from "../styles/login.module.css";
import subText1 from "../assets/images/sub_text01.png";
import subText2 from "../assets/images/sub_text02.png";
import subText3 from "../assets/images/sub_text03.png";
import goItem from "../assets/images/air.png";
import mainn1 from "../assets/images/main_n1.png";
import mainn2 from "../assets/images/main_n2.png";
import mainn3 from "../assets/images/main_n3.png";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../lib/api/tokenCrypto";
import { getUsers } from "../modules/user";
import { useDispatch } from "react-redux";
import { SuccessAlert } from "../components/alert/Alert";
import { RiErrorWarningFill } from "react-icons/ri";
function Login() {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/");
      setLoad(false);
    } else {
      setLoad(true);
    }
  }, []);

  const [loginId, setLoginId] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const onChange_id = useCallback((e) => {
    setLoginId(e.target.value);
  }, []);

  const onChange_pass = useCallback((e) => {
    setLoginPass(e.target.value);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("digibookReme")) {
      // decrypt(localStorage.getItem("meta_info"), "meta_info")

      let data = decrypt(localStorage.getItem("digibookReme"), "digibookReme");

      // console.log(data);
      setLoginId(data.id);
      setLoginPass(data.password);
    }
  }, []);
  const btn_login = () => {
    if (!loginId || !loginPass) {
      setSuccessTitle("로그인 불가");
      setsuccessCont(`빈칸을 입력해주세요.`);
      setSuccessAlert(true);

      return;
    }
    let sendData = {
      email: loginId,
      password: loginPass,
    };
    // alert(checkYn);

    if (checkYn) {
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/signin",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            localStorage.setItem(
              "digibooktoken",
              encrypt(result.messageinfo.message, "digibooktoken")
            );
            dispatch(getUsers(result.messageinfo.message));

            if (checkYn) {
              localStorage.setItem(
                "digibookReme",
                encrypt({ id: loginId, password: loginPass }, "digibookReme")
              );
            } else {
              localStorage.removeItem("digibookReme");
            }

            navigate("/list");

            // setMoveAlert(true);
          } else {
            if (result.messageinfo.message === "탈퇴한 계정") {
            } else {
              setSuccessTitle("로그인 실패");
              setsuccessCont(`아이디 또는 비밀번호를 확인해주세요.`);
              setSuccessAlert(true);
            }
          }
        }
      );
    } else {
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/signin2",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            localStorage.setItem(
              "digibooktoken",
              encrypt(result.messageinfo.message, "digibooktoken")
            );
            dispatch(getUsers(result.messageinfo.message));

            if (checkYn) {
              localStorage.setItem(
                "digibookReme",
                encrypt({ id: loginId, password: loginPass }, "digibookReme")
              );
            } else {
              localStorage.removeItem("digibookReme");
            }

            navigate("/list");

            // setMoveAlert(true);
          } else {
            if (result.messageinfo.message === "탈퇴한 계정") {
            } else {
              setSuccessTitle("로그인 실패");
              setsuccessCont(`아이디 또는 비밀번호를 확인해주세요.`);
              setSuccessAlert(true);
            }
          }
        }
      );
    }
  };
  const [successAlert, setSuccessAlert] = useState(false);
  const [successCont, setsuccessCont] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [checkYn, setCheckYn] = useState(true);
  const enterkey = (e) => {
    if (e.key === "Enter") {
      btn_login(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const logCheck = () => {
    // alert("?s");

    return setCheckYn(!checkYn);
  };
  return (
    <>
      {load && (
        <>
          {successAlert && (
            <SuccessAlert
              content={successCont}
              title={successTitle}
              setSuccessAlert={setSuccessAlert}
            />
          )}
          <div id="wrapper" className={log.login_wrap2}>
            <div id="container">
              <div className={log.login_wrap}>
                <div className={log.wrap}>
                  <div className={log.login_box}>
                    <div className={log.login_box_top}>
                      <h2>로그인</h2>
                      <ul className={log.login_top_img}>
                        <li>
                          <img src={subText1} />{" "}
                        </li>{" "}
                        <li>
                          <img src={subText2} />{" "}
                        </li>{" "}
                        <li>
                          <img src={subText3} />{" "}
                        </li>
                      </ul>
                    </div>
                    <div className={log.login_box_middle}>
                      <div className={log.login_middle_content}>
                        <div className={log.login_content}>
                          <input
                            type="text"
                            onChange={onChange_id}
                            value={loginId}
                          />
                          <input
                            type="password"
                            style={{
                              display: "block",
                              height: "0px",
                              border: "0",
                            }}
                          />
                          <input
                            type="password"
                            onChange={onChange_pass}
                            value={loginPass}
                            onKeyPress={enterkey}
                            autoComplete="off"
                          />
                        </div>{" "}
                        <div
                          className={log.login_content_btn}
                          onClick={btn_login}
                        >
                          <span>로그인</span>
                        </div>
                      </div>
                      <div
                        className={log.login_check_div}
                        // onClick={() => logCheck()}
                      >
                        <label onClick={() => logCheck()}>
                          {" "}
                          <div
                            className={`${log.login_check} ${log.login_check_view}`}
                          >
                            {" "}
                            <BsCheck
                              style={{ display: checkYn ? "block" : "none" }}
                            />
                          </div>{" "}
                          <span>암호 기억하기</span>
                        </label>
                      </div>
                    </div>
                    <div className={log.login_box_bottom}>
                      <button>아이디 찾기</button>
                      <button>비밀번호 찾기</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Login;
