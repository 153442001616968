import React, { useState, useEffect, useRef } from "react";

import logo from "../logo.svg";
// import "../App.css";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import axios from "axios";
import { CommonAxios } from "../components/CommonAxios";
import Modal from "../lib/Modal";
import main from "../styles/main.module.css";
import subText1 from "../assets/images/sub_text01.png";
import subText2 from "../assets/images/sub_text02.png";
import subText3 from "../assets/images/sub_text03.png";
import goItem from "../assets/images/air.png";
import mainn1 from "../assets/images/main_n1.png";
import mainn2 from "../assets/images/main_n2.png";
import mainn3 from "../assets/images/main_n3.png";
import mainn4 from "../assets/images/main_n4.png";
import { Link, useNavigate } from "react-router-dom";
import {
  BiSolidUpArrow,
  BiSolidDownArrow,
  BiSolidLeftArrow,
  BiSolidRightArrow,
} from "react-icons/bi";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
  Grid,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
function Main() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  useEffect(() => {
    if (!load) {
      let sendData = {
        data: "",
      };
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/select_main_proejct",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            setData(result.messageinfo.message);
            setLoad(true);
          } else {
            //   alert(result.messageinfo.message);
          }
        }
      );
    }
  }, []);
  const navigate = useNavigate();
  const btn_move = (data1) => {
    if (user) {
      sessionStorage.setItem("7434DA3SSd", data1);
      // if (lo.getItem("savePage")) {
      //   let dataLo = sessionStorage.getItem("savePage");

      //   navigate(dataLo);
      //   sessionStorage.removeItem("savePage");
      // } else {
      //   navigate("/");
      // }

      navigate("/list");
    } else {
      sessionStorage.setItem("7434DA3SSd", data1);
      navigate("/login");
    }
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const SwiperItem = ({ data }) => {
    return (
      <div
        className={
          load
            ? `${main.intro_item} ${main.scroll_active}`
            : `${main.intro_item} `
        }
      >
        <div onClick={() => btn_move(data.no)} style={{ cursor: "pointer" }}>
          <img src={`/project/${data.no}/${data.project_img}`} />
        </div>
      </div>
    );
  };
  return (
    <>
      <div id="wrapper" style={{ height: "100%" }}>
        <div id="container">
          <div className={main.intro}>
            <div className={main.intro_bg_1}></div>
            <div className={main.intro_bg_2}></div>
            <div className={main.intro_bg_3}></div>
            <div className={main.intro_bg_4}></div>
            {load && (
              <>
                <div className={main.wrap}>
                  {" "}
                  <div
                    className={
                      load
                        ? `${main.intro_bottom}  ${main.scroll_active}`
                        : `${main.intro_bottom} `
                    }
                  >
                    <div className={main.go_item}>
                      <a href="#n" className={main.intro_go}>
                        <img src={goItem} />
                      </a>
                    </div>
                  </div>
                  <div className={main.intro_box}>
                    <div
                      className={`${main.list_left_arrow_div} ${main.list_arrow_up_div} `}
                      ref={prevRef}
                    >
                      <BiSolidLeftArrow />
                    </div>{" "}
                    <div
                      className={`${main.list_right_arrow_div} ${main.list_arrow_down_div} `}
                      ref={nextRef}
                    >
                      <BiSolidRightArrow />
                    </div>
                    <Swiper
                      className="intro_swiper"
                      slidesPerView={1}
                      // direction={"vertical"}
                      ref={setSwiper}
                      {...parmss}
                      style={{ position: "relative", display: "flex" }}
                      modules={[Navigation, Grid, Pagination]}
                      breakpoints={{
                        640: {
                          slidesPerView: 3,
                        },
                      }}
                    >
                      {data &&
                        data.map((data) => {
                          return (
                            <SwiperSlide>
                              <SwiperItem data={data} />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
